import revive_payload_client_cjVj9QwqU7 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_URzAEyrkeo from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_C1jY8vP6hk from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VpW9OWepiX from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bo4BvycvMn from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GphtLsSvoN from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_MWZ8DTXHBk from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_PZFUWtl5NF from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.34.7_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/components.plugin.mjs";
import prefetch_client_PM2HTVMt02 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.15.4_@biomejs+biome@1.9.4_@parcel+watcher@2.4.1_@types+node@22.10.5_db0@0.2.1_en_375e791e0f920b70362177b5d22f13af/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/pwa-icons-plugin.ts";
import pwa_client_9ewBernAk5 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_rollup@4.34.7_vi_9e3b9623b7a27e2ae52965ae34fdbc93/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/workflows/console-ui/packages/protected/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/workflows/console-ui/apps/console/.nuxt/formkitPlugin.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/workflows/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/workflows/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import plugin_8fMk05vpWN from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/workflows/console-ui/packages/ui/src/runtime/plugin.ts";
export default [
  revive_payload_client_cjVj9QwqU7,
  unhead_URzAEyrkeo,
  router_C1jY8vP6hk,
  payload_client_VpW9OWepiX,
  navigation_repaint_client_bo4BvycvMn,
  check_outdated_build_client_GphtLsSvoN,
  chunk_reload_client_MWZ8DTXHBk,
  plugin_vue3_PZFUWtl5NF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PM2HTVMt02,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_9ewBernAk5,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  formkitPlugin_pZqjah0RUG,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  plugin_8fMk05vpWN,
  plugin_PXQeuxRKoc
]