
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91rid_93dgyMWWU9fKMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue?macro=true";
import { default as index09IO1qV1BKMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue?macro=true";
import { default as _91connectorId_93pPQASykoTaMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as api_45clientsSrLAyDYKsxMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/api-clients.vue?macro=true";
import { default as indexqncYKthOhAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/index.vue?macro=true";
import { default as usersJOJU2AKATOMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/users.vue?macro=true";
import { default as api_45adminKygpjikY03Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/api-admin.vue?macro=true";
import { default as app_45configSc8CwSVdSmMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/app-config.vue?macro=true";
import { default as create_45workspace2NcLbKQ1uaMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/create-workspace.vue?macro=true";
import { default as indexyN4kpyFgDBMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index.vue?macro=true";
import { default as _91id_93xNU4PMkOXtMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/[id].vue?macro=true";
import { default as createAuyz77TXG2Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/create.vue?macro=true";
import { default as indexV5zCQl1uN8Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/index.vue?macro=true";
import { default as usersDp3wiRptUjMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users.vue?macro=true";
import { default as _91connId_93UkXhE8arLBMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue?macro=true";
import { default as indexvtZCOlS7RiMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue?macro=true";
import { default as editYecJYFZmFOMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue?macro=true";
import { default as indexFnGp71Oz9VMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index.vue?macro=true";
import { default as _91id_93YpdYY0BZ6SMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id].vue?macro=true";
import { default as index8GUmIU5CGAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/index.vue?macro=true";
import { default as app_45statusp1k6BaByKaMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/app-status.vue?macro=true";
import { default as changelogDeGUtbsgi4Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/changelog.vue?macro=true";
import { default as influencing_45claims0DcciAw2jEMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue?macro=true";
import { default as _91id_93d7ZaMKETf9Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id].vue?macro=true";
import { default as claimsGfnwZqok0GMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims.vue?macro=true";
import { default as dashboardDRDY6ZNtKEMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/dashboard.vue?macro=true";
import { default as edit4etmseOF4nMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/edit.vue?macro=true";
import { default as indexh5w8XyRNDgMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/index.vue?macro=true";
import { default as createfxDKV9lyIaMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/create.vue?macro=true";
import { default as indextvt0JtqxyGMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/index.vue?macro=true";
import { default as exclusionsA8FEcyWzdlMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions.vue?macro=true";
import { default as indexDWCwiLditCMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/index.vue?macro=true";
import { default as maintenanceud8cPGJobNMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/maintenance.vue?macro=true";
import { default as providers_45impactedgNf0Cq2yFdMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue?macro=true";
import { default as _91id_93uCPmH7mj50Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id].vue?macro=true";
import { default as indexfUb6sZlj7mMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/index.vue?macro=true";
import { default as policiestS6TC3Xuq5Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies.vue?macro=true";
import { default as reset_45password_45confirm7rBPdaUyyGMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password-confirm.vue?macro=true";
import { default as reset_45passwordwFwPnE786bMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password.vue?macro=true";
import { default as signinBNCRKvIQTKMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signin.vue?macro=true";
import { default as confirm0KkTXJKgZ9Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup/confirm.vue?macro=true";
import { default as indexT5S6KNtwKnMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup/index.vue?macro=true";
import { default as signupIylKkg2SqPMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup.vue?macro=true";
import { default as welcomeimaWIc1gaTMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/welcome.vue?macro=true";
import { default as auth_45callbackvzPvcsneV7Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45login2NyvXp0YlaMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustPEZIB9azrMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
import { default as samlR4ARVeTuD4Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/pages/saml.vue?macro=true";
export default [
  {
    name: "connectorId",
    path: "/:connectorId()",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue"),
    children: [
  {
    name: "connectorId-claim-lab-claimId-rid",
    path: "claim-lab/:claimId()/:rid()",
    meta: _91rid_93dgyMWWU9fKMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId]/[rid].vue")
  },
  {
    name: "connectorId-claim-lab",
    path: "claim-lab",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue")
  }
]
  },
  {
    name: "account-api-clients",
    path: "/account/api-clients",
    meta: api_45clientsSrLAyDYKsxMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/api-clients.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexqncYKthOhAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/index.vue")
  },
  {
    name: "account-users",
    path: "/account/users",
    meta: usersJOJU2AKATOMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/users.vue")
  },
  {
    name: "admin-api-admin",
    path: "/admin/api-admin",
    meta: api_45adminKygpjikY03Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/api-admin.vue")
  },
  {
    name: "admin-app-config",
    path: "/admin/app-config",
    meta: app_45configSc8CwSVdSmMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/app-config.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexyN4kpyFgDBMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index.vue"),
    children: [
  {
    name: "admin-index-create-workspace",
    path: "create-workspace",
    meta: create_45workspace2NcLbKQ1uaMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/create-workspace.vue")
  }
]
  },
  {
    name: usersDp3wiRptUjMeta?.name,
    path: "/admin/users",
    meta: usersDp3wiRptUjMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users.vue"),
    children: [
  {
    name: "admin-users-id",
    path: ":id()",
    meta: _91id_93xNU4PMkOXtMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/[id].vue")
  },
  {
    name: "admin-users-create",
    path: "create",
    meta: createAuyz77TXG2Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/create.vue")
  },
  {
    name: "admin-users",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/index.vue")
  }
]
  },
  {
    name: _91id_93YpdYY0BZ6SMeta?.name,
    path: "/admin/workspace/:id()",
    meta: _91id_93YpdYY0BZ6SMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id].vue"),
    children: [
  {
    name: "admin-workspace-id",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index.vue"),
    children: [
  {
    name: "admin-workspace-id-index-connector-connId",
    path: "connector/:connId()",
    meta: _91connId_93UkXhE8arLBMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue")
  },
  {
    name: "admin-workspace-id-index-connector",
    path: "connector",
    meta: indexvtZCOlS7RiMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue")
  },
  {
    name: "admin-workspace-id-index-edit",
    path: "edit",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue")
  }
]
  }
]
  },
  {
    name: "admin-workspace",
    path: "/admin/workspace",
    meta: index8GUmIU5CGAMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/index.vue")
  },
  {
    name: "app-status",
    path: "/app-status",
    meta: app_45statusp1k6BaByKaMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/app-status.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    meta: changelogDeGUtbsgi4Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/changelog.vue")
  },
  {
    name: "claims",
    path: "/claims",
    meta: claimsGfnwZqok0GMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims.vue"),
    children: [
  {
    name: "claims-id",
    path: ":id()",
    meta: _91id_93d7ZaMKETf9Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id].vue"),
    children: [
  {
    name: "claims-id-insightId-influencing-claims",
    path: ":insightId()/influencing-claims",
    meta: influencing_45claims0DcciAw2jEMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue")
  }
]
  }
]
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboardDRDY6ZNtKEMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/dashboard.vue")
  },
  {
    name: exclusionsA8FEcyWzdlMeta?.name,
    path: "/exclusions",
    meta: exclusionsA8FEcyWzdlMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions.vue"),
    children: [
  {
    name: "exclusions-id-edit",
    path: ":id()/edit",
    meta: edit4etmseOF4nMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/edit.vue")
  },
  {
    name: "exclusions-id",
    path: ":id()",
    meta: indexh5w8XyRNDgMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/index.vue")
  },
  {
    name: "exclusions-create",
    path: "create",
    meta: createfxDKV9lyIaMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/create.vue")
  },
  {
    name: "exclusions",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexDWCwiLditCMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceud8cPGJobNMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/maintenance.vue")
  },
  {
    name: policiestS6TC3Xuq5Meta?.name,
    path: "/policies",
    meta: policiestS6TC3Xuq5Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies.vue"),
    children: [
  {
    name: "policies-id",
    path: ":id()",
    meta: _91id_93uCPmH7mj50Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id].vue"),
    children: [
  {
    name: "policies-id-providers-impacted",
    path: "providers-impacted",
    meta: providers_45impactedgNf0Cq2yFdMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue")
  }
]
  },
  {
    name: "policies",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/index.vue")
  }
]
  },
  {
    name: "reset-password-confirm",
    path: "/reset-password-confirm",
    meta: reset_45password_45confirm7rBPdaUyyGMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password-confirm.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordwFwPnE786bMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password.vue")
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinBNCRKvIQTKMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signin.vue")
  },
  {
    name: signupIylKkg2SqPMeta?.name,
    path: "/signup",
    meta: signupIylKkg2SqPMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup.vue"),
    children: [
  {
    name: "signup-confirm",
    path: "confirm",
    meta: confirm0KkTXJKgZ9Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup/confirm.vue")
  },
  {
    name: "signup",
    path: "",
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup/index.vue")
  }
]
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomeimaWIc1gaTMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/welcome.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callbackvzPvcsneV7Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45login2NyvXp0YlaMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statustPEZIB9azrMeta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  },
  {
    name: "AuthSaml",
    path: "/saml",
    meta: samlR4ARVeTuD4Meta || {},
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/pages/saml.vue")
  }
]